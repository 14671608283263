import React from 'react';
import Editor from './editor';
import Preview from './preview';

import { compile, initTemplate } from './resumark';
import 'bootstrap/dist/css/bootstrap.min.css';
import templatesConfig from './templates.json'

class Resumarker extends React.Component {
  state = {
    content: '',
    template: '',
  }
  onContentChange = (content) => {
    this.setState({
      content: content
    });
  };

  constructor(props) {
    super(props);

    var config = templatesConfig['DevResume'];
    var dir = process.env.PUBLIC_URL + '/templates/';
    fetch(dir + config.name + '/default.rmk')
    .then((resp) => resp.text())
    .then((t) => {
      console.log(t)
      this.setState({
        content: t
      })
    });

    initTemplate(config, dir).then((t) => this.state.template = t);
  }

  render() {
    return (
      <div className='min-vh-100 d-flex flex-row align-items-stretch align-self-stretch'>
        <Editor className='flex-grow-1 m-2' content={this.state.content} onChange={this.onContentChange}> </Editor>
        <Preview className='m-2' style={{width: '10.5in'}} content={compile(this.state.content, this.state.template)}></Preview>
      </div>  
    )
  }
}

function App() {
  return (
    <div className="container-fluid">
        <Resumarker />
    </div>
  );
}

export default App;
