import React from 'react';
import AceEditor from "react-ace";
// import FilePicker from "./filePicker"
// import * as gdrive from "./gdrive"
import "ace-builds/src-noconflict/theme-github";


class Editor extends React.Component {
  state = {
    showPicker: true,
    isSignedIn: false,
    currentFile: null,
    fileList: []
  }


  componentDidMount() {
  }
  
  render() {
    return (
      <div className={this.props.className}>
        <AceEditor
            className='w-100 h-100'
            theme="github"
            onChange={this.props.onChange}
            value={this.props.content}
            editorProps={{ $blockScrolling: true }}
            fontSize={16}
            showPrintMargin={false}
            highlightActiveLine={true}
            wrapEnabled={true}
            setOptions={{
              showLineNumbers: true,
              tabSize: 2,
            }}
          />
      </div>
    )
  }
}

export default Editor