import React from 'react';

function printResume() {
  var iframe = window.frames[0];
  iframe.focus();
  iframe.print(); 
}

class Preview extends React.Component {
  render() {
    return (
      <div className={this.props.className} style={this.props.style}>
        <div>
          <button onClick={printResume}>Print</button>
        </div>
        <div className='w-100 h-100'>
          <iframe className='w-100 h-100' frameBorder={0} srcDoc={this.props.content} title="preview"></iframe>
        </div>
      </div>
    )
  }
}

export default Preview

